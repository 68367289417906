/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import 'mixins';
@import 'theme';

body {
    @include default-font();
    background-color: $theme-background-color-1;
}

.container-fluid {
    .row {
        .row {
            max-width: 1350px;
        }
    }
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom_mat_form_time {

    .mat-form-field-wrapper {
        .mat-form-field-flex {
            .mat-form-field-infix {
                border: 0;
            }
        }
    }
}



html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
